import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = (props) => {
  return(
    <Layout>
      <SEO title="Privacy & Cookie Policy" />
      <section id="privacy-cookie-policy">
          <div className="container">
            <h1>Informativa sulla Privacy e Policy sui Cookies:</h1>
            <p>La presente informativa è resa da Stasi Group SRL in qualità di Titolare del trattamento del sito web qr-pco.stasigroup.it ai sensi del Regolamento UE 2016/679 (GDPR), della Direttiva ePrivacy 2002/58/EC (Cookie Law) e della normativa nazionale in vigore.</p>
            <div className="contenuto-pagina">
              <h3>Cosa sono e a cosa servono i "cookie"</h3>
              <p>I cookie sono stringhe di testo di piccole dimensioni che i siti visitati dall'utente inviano al suo terminale (solitamente al browser), dove vengono memorizzati per essere poi ritrasmessi agli stessi siti alla successiva visita del medesimo utente. I cookie assolvono a tutta una serie di attività che facilitano l'uso del sito da parte di voi utenti, attività come la memorizzazione delle vostre preferenze, l'autenticazione di utenze, il monitoraggio delle sessioni o il salvataggio delle impostazioni della vostra configurazione. Oltre ai cookie tecnici strettamente necessari, potreste ricevere sul vostro dispositivo anche cookie di siti diversi (c.d. cookie "terze parti"), collegati al sito che state navigando ma impostati direttamente dal gestore di queste terze parti e utilizzati per finalità e secondo modalità da questi definiti. Alcune delle finalità di utilizzo potrebbero necessitare del vostro consenso come utenti. Nel caso abbiate già prestato il consenso potrete aggiornarlo o revocarlo liberamente in qualsiasi momento seguendo le istruzioni del paragrafo "come prestare o revocare il consenso" presente in questo documento.</p>
              
              <h3>Tipologia di cookie utilizzati dal presente sito</h3>
              <p>Il sito Stasi Group QR PCO Landing Page utilizza cookie tecnici e cookie di profilazione.<br />
                In dettaglio, il sito utilizza i seguenti cookie:
              </p>
                      
              <h3>Cookie tecnici necessari alla navigazione</h3>
              <p>
                (indispensabili per un corretto funzionamento del sito web che non necessitano di consenso)
                Sono i c.d. cookie di navigazione o di sessione, che garantiscono la normale navigazione e fruizione del sito web (permettendo, ad esempio, di realizzare un acquisto o autenticarsi per accedere ad aree riservate); <b>cookie di funzionalità </b>, che permettono all'utente la navigazione in funzione di una serie di criteri selezionati (ad esempio, la lingua, i prodotti selezionati per l'acquisto) al fine di migliorare il servizio reso allo stesso. I cookie di terze parti, qualora siano adottati strumenti che riducono il potere identificativo dei cookie non sono soggetti ad alcun obbligo da parte del titolare del sito.
              </p>
              
              <h3>Cookie di profilazione (statistici)</h3>
              <p>
                Questo Sito Web usa Cookie o altri Identificatori per misurare il traffico e analizzare il comportamento degli utenti con l"obiettivo di migliorare il Servizio.
              </p>

              <h4>Google Analytics (Google Inc.)</h4>
              <p>
                Google Analytics è un servizio di analisi web fornito da Google Inc. ("Google"). Google utilizza i dati Personali raccolti allo scopo di tracciare ed esaminare l'utilizzo di questo sito, compilare report e condividerli con gli altri servizi sviluppati da Google.<br />
                Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.<br />
                Dati Personali raccolti: Cookie e Dati di utilizzo.<br />
                Luogo del trattamento: USA 
              </p>
              <ul>
                <li>Privacy Policy <a href="https://www.google.com/intl/it/policies/privacy/" target="_blank" rel="noopener noreferrer">https://www.google.com/intl/it/policies/privacy/</a></li>
                <li>Opt Out  <a href="https://tools.google.com/dlpage/gaoptout?hl=it" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=it</a></li>
              </ul>
              
              <h3>Cookie di profilazione (tracciamento/marketing)</h3>
              <p>
                Questo Sito Web usa Cookie o altri Identificatori per fornire contenuti commerciali personalizzati in base al comportamento dell"Utente e per gestire, fornire e tenere traccia degli annunci pubblicitari.
              </p>
            
              <h4>Facebook Pixel</h4>
              <p>
                Il monitoraggio conversioni di Facebook Ads è un servizio di statistiche fornito da Facebook, Inc. che collega i dati provenienti dal network di annunci Facebook con le azioni compiute all"interno di questo sito. <br />
                Dati Personali raccolti: Cookie e Dati di utilizzo. <br />
                Luogo del trattamento: USA 
              </p>
              <ul>
                <li>Privacy Policy <a href="https://www.google.com/intl/it/policies/privacy/" target="_blank" rel="noopener noreferrer">https://www.google.com/intl/it/policies/privacy/</a></li>
                <li>Opt Out <a href="https://tools.google.com/dlpage/gaoptout?hl=it" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=it</a></li>
               </ul>


              <h3>Modalità del trattamento</h3>
              <p>Il trattamento è compiuto dal Titolare con strumenti automatizzati.</p>
              
              <h3>Luogo del trattamento </h3>
              <p>
                Oltre al Titolare potrebbero avere accesso ai dati altri soggetti coinvolti nella gestione del sito (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) o soggetti esterni (come fornitori di servizi tecnici terzi, hosting provider, società informatiche, agenzie di comunicazione) nominati, ove necessario, Responsabili del Trattamento da parte del Titolare.<br />
                I dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. I Dati Personali dell'Utente potrebbero essere trasferiti in un paese diverso da quello in cui l'Utente si trova. I dettagli sul luogo del trattamento per le terze parti sono inseriti nelle specifiche dei "cookie di terze parti".
              </p>

              <h3>Conferimento dei dati e consenso</h3>
              <p>Fatta eccezione per i cookie tecnici strettamente necessari alla normale navigazione, il conferimento dei dati è rimesso alla vostra volontà (come utente e interessato) decidendo di navigare sul sito dopo aver preso visione dell'informativa breve contenuta nell'apposito banner e di usufruire dei servizi che comportano l'installazione di cookie.</p>

              <h3>Come prestare o revocare il consenso</h3>
              <p>
                Al primo accesso al nostro sito vi viene chiesto di prestare il vostro consenso all'uso di Cookie o altri identificatori attraverso il banner dedicato. Il sito necessita di una vostra azione chiara (click su uno dei pulsanti del banner) per dimostrare la vostra volontà all'accettazione di uno o più cookie. Potrete aggiornare tali preferenze tramite l'apposito pulsante per le impostazioni di consenso presente su ogni pagina del sito.
              </p>

              <p>Come utente puoi inoltre applicare ulteriori forme di gestione dei cookie con i seguenti metodi:</p>
                
              <h3>Preferenze regolate dal browser o dal dispositivo</h3>
              <p>
                Ogni utente puà² impostare e aggiornare le proprie preferenze direttamente dal browser o dal dispositivo. Ogni browser presenta procedure diverse per la gestione delle impostazioni. A titolo puramente esemplificativo, giacchà© le procedure potrebbero cambiare con successivi aggiornamenti, ti indichiamo le procedure per i più diffusi browser:
              </p>
              <ul>
                <li>
                  <b>Google Chrome:</b> Cliccare la chiave inglese nell'angolo in alto a destra e selezionare "˜Impostazioni'. Selezionare "Mostra impostazioni avanzate" e cambiare le impostazioni della "˜Privacy'.
                </li>
                <li>
                  <b>Mozilla Firefox:</b> Dal menu a tendina nell'angolo in alto a sinistra selezionare "˜Opzioni'. Nella finestra di pop-up selezionare "˜Privacy'. Qui potrete regolare le impostazioni dei vostri cookie.
                </li>
                <li>
                  <b>Microsoft Internet Explorer: </b> Cliccare l'icona "˜Strumenti' nell'angolo in alto a destra e selezionare "˜Opzioni internet'. Nella finestra pop-up selezionare "˜Privacy'. Da qui regolate le impostazioni dei cookie.
                </li>
                <li>
                  <b>Safari:</b> Dal menu di impostazione a tendina nell'angolo in alto a destra selezionare "˜Preferenze'. Selezionare "˜Sicurezza' e qui potrete regolare le impostazioni dei vostri cookie.
                </li>
                <li>
                  <b>opera:</b> Dal menu di impostazione a tendina nell'angolo in alto a destra selezionare "˜Preferenze'. Selezionare "˜Sicurezza' e qui potrete regolare le impostazioni dei vostri cookie.
                </li>
              </ul>

              <p>
                Per maggiori informazioni potete cliccare sui seguenti indirizzi per ognuno dei browser citati:
              </p>
              <ul>
                <li>
                  Google Chrome <a href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies" target="_blank" rel="noopener noreferrer">https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies</a>
                </li>
                <li>
                  Mozilla Firefox <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank" rel="noopener noreferrer">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a>
                </li>
                <li>
                  Microsoft Internet Explorer <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank" rel="noopener noreferrer">http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies</a>
                </li>
                <li>
                  Apple Safari <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/" target="_blank" rel="noopener noreferrer">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/</a>
                </li>
                <li>Opera <a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank" rel="noopener noreferrer">https://help.opera.com/en/latest/web-preferences/#cookies</a></li>
              </ul>
              <p>
                Per quanto riguarda cookies di terze parti, per gestire, aggiornare o revocare il proprio consenso potete visitare il relativo link di opt-out (qualora disponibile), utilizzando gli strumenti descritti nella privacy policy della terza parte o contattando la stessa.
              </p>

              <h3>SERVIZI DI GESTIONE ESTERNI</h3>
              <p>
                Gli utenti possono avvalersi di servizi online con cui è possibile gestire le preferenze di tracciamento della maggior parte degli strumenti pubblicitari. Tra questi:
              </p>
              <ul>
                <li>YourOnlineChoices (EU) <a href="http://www.youronlinechoices.eu/" target="_blank" rel="noopener noreferrer">http://www.youronlinechoices.eu/</a></li>
                <li>Network Advertising Initiative (USA) <a href="https://www.networkadvertising.org/understanding-digital-advertising" target="_blank" rel="noopener noreferrer">https://www.networkadvertising.org/understanding-digital-advertising</a></li>
                <li>Digital Advertising Alliance (USA) <a href="https://www.aboutads.info/consumers/" target="_blank" rel="noopener noreferrer">https://www.aboutads.info/consumers/</a></li>
                <li>DAAC (Canada) <a href="https://youradchoices.ca/understanding-online-advertising/" target="_blank" rel="noopener noreferrer">https://youradchoices.ca/understanding-online-advertising/</a></li>
                <li>DDAI (Giappone) <a href="http://www.ddai.info/optout/" target="_blank" rel="noopener noreferrer">http://www.ddai.info/optout/</a></li>
              </ul>

              <h3>I DIRITTI SUI SUOI DATI</h3>
              <p>
                In ogni momento l'interessato potrà esercitare i propri diritti nei confronti del Titolare del Trattamento, ai sensi degli artt. 15 e ss. del GDPR, ovvero potrà ottenere:
              </p>
              <ul>
                <li>
                  l"aggiornamento, la rettifica, l'integrazione dei dati, la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati nonchà© il diritto di reclamo all'Autorità Garante.
                </li>
              </ul>
              <h3>COME ESERCITARE QUESTI DIRITTI</h3>
              <p>Per l'esercizio di tali diritti, o per ottenere qualsiasi altra informazione in merito, le richieste vanno rivolte a:</p>

              <h4>Titolare del Trattamento dei Dati</h4>
              <p>
                Stasi Group SRL<br />
                P. IVA IT03496331210<br />
                Via Carpentieri, 2 <br />
                Torre del Greco (NA) <br />
                +39 081 85 88 873 <br />
              </p>
              <h4> Indirizzo email del Titolare: </h4>
              <a href="mailto:gdpr@stasigroup.it">gdpr@stasigroup.it</a>
            </div>
         </div>
      </section>
    </Layout>
  )
}
export default PrivacyPolicy    